// import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm, Controller } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { Axios } from '../components/common/Axios';
import { ToastContainer } from 'react-toastify';
import { notify, notifyErr } from '../components/common/norify';
import Chip from '@mui/material/Chip';
import { FaTrash } from 'react-icons/fa'
import Stack from '@mui/material/Stack';
import NoImg from '../assets/No-Image.png'
import Swal from "sweetalert2";

const Setting = () => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const [id, setId] = useState('');
  const [logo, setLogo] = useState('');
  const [pending, setPending] = useState(false)
  const [logoWhite, setLogoWhite] = useState('');

  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneIcon, setPhoneIcon] = useState('');


  const [formData, setFormData] = useState({
    email: '',
    address: '',
    logo: '',
    logoWhite: '',
    mapUrl: '',
    smtp_email: '',
    smtp_password: '',
    facebookUrl: '',
    instagramUrl: '',
    linkedInUrl: '',
    twitterUrl: '',
    youtubeUrl: '',
    threads: '',
    site_key: '',
    site_secret: '',
    blocked_emails: '',
    domains: '',
    whatsapp: ''
  })


  const handleClose = () => {
    setFormData({
      email: '',
      address: '',
      logo: '',
      logoWhite: '',
      mapUrl: '',
      smtp_email: '',
      smtp_password: '',
      facebookUrl: '',
      instagramUrl: '',
      linkedInUrl: '',
      twitterUrl: '',
      youtubeUrl: '',
      threads: '',
      site_key: '',
      site_secret: '',
      blocked_emails: '',
      domains: '',
      whatsapp: ''

    })
    reset({
      email: '',
      address: '',
      logo: '',
      logoWhite: '',
      mapUrl: '',
      smtp_email: '',
      smtp_password: '',
      facebookUrl: '',
      instagramUrl: '',
      linkedInUrl: '',
      twitterUrl: '',
      youtubeUrl: '',
      threads: '',
      site_key: '',
      site_secret: '',
      blocked_emails: '',
      domains: '',
      whatsapp: ''
    })
  }


  const getData = async () => {
    try {
      const contactData = await Axios.get(`/api/contact`);
      let contact = contactData.data.data;
      if (contact) {
        setFormData({
          email: contact.email,
          address: contact.address,
          mapUrl: contact.mapUrl,
          smtp_email: contact.smtp_email,
          smtp_password: contact.smtp_password,
          facebookUrl: contact.facebookUrl,
          instagramUrl: contact.instagramUrl,
          linkedInUrl: contact.linkedInUrl,
          twitterUrl: contact.twitterUrl,
          youtubeUrl: contact.youtubeUrl,
          threads: contact.threads,
          site_key: contact.site_key,
          site_secret: contact.site_secret,
          blocked_emails: contact.blocked_emails,
          domains: contact.domains,
          whatsapp: contact.whatsapp
        });
        setId(contact._id)
        setLogo(contact.logo)
        setLogoWhite(contact.logoWhite)
        setPhoneNumbers(contact.phone)
      }


    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    getData();
  }, []);


  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };


  const { register, handleSubmit, reset, formState: { errors } } = useForm();


  const onSubmit = async () => {
    try {
      const response = await Axios.put(`/api/contact/${id}`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
      if (response.status === 200 || 201) {
        notify('Contact info Updated Successfully');
        handleClose();
        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      }
    } catch (error) {
      notifyErr('Something went wrong')
      console.error(error);
    }
  }

  const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 } } = useForm();
  const onSubmitPhone = async (data) => {
    try {
      const response = await Axios.post(`/api/contact/${id}/phone-number`, {
        phoneIcon: phoneIcon,
        phoneNumber: phoneNumber
      },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
      if (response.status === 200 || 201) {
        notify('Phone added Successfully');
        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      }
    } catch (error) {
      notifyErr('Something went wrong')
      console.error(error);
    } finally {
      setPending(false)
    }
  };


  // const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm();
  // const onSubmitSocialMedia = async (data) => {
  //   try {
  //     const response = await Axios.post(`/api/contact/${id}/social-media`, {
  //       platform: platform,
  //       profileLink: profileLink,
  //       plateformIcon: platformIcon
  //     },
  //       {
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //       });
  //     if (response.status === 200 || 201) {
  //       notify('Social Media added Successfully');
  //       setTimeout(() => {
  //         window.location.reload(false);
  //       }, 1500);
  //     }
  //   } catch (error) {
  //     notifyErr('Something went wrong')
  //     console.error(error);
  //   } finally {
  //     setPending(false)
  //   }
  // };



  const handleDeletePhone = async (id_) => {
    try {
      const response = await Axios.delete(`/api/contact/${id}/phone-number/${id_}`);
      if (response.status === 200) {
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      }
      Swal.fire({
        title: 'Deleted!',
        text: 'Your data has been deleted.',
        icon: 'success',
        confirmButtonColor: '#0C0833',
      });
    } catch (error) {
      console.error(error);

      Swal.fire({
        title: 'Error',
        text: 'An error occurred while deleting the data.',
        icon: 'error',
      });
    }
  };

  const confirmDeletePhone = (id) => {
    Swal.fire({
      title: 'Are you sure to Delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '##0C0833',
      cancelButtonColor: 'grey',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {

      if (result.value) {
        console.log(result)
        handleDeletePhone(id);
      }
    });
  };


  const handleDeleteSocialMedia = async (id_) => {
    try {
      const response = await Axios.delete(`/api/contact/${id}/social-media/${id_}`);
      if (response.status === 200) {
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      }
      Swal.fire({
        title: 'Deleted!',
        text: 'Your data has been deleted.',
        icon: 'success',
        confirmButtonColor: '#0C0833',
      });
    } catch (error) {
      console.error(error);

      Swal.fire({
        title: 'Error',
        text: 'An error occurred while deleting the data.',
        icon: 'error',
      });
    }
  };

  // const confirmDeleteSocialMedia = (id) => {
  //   Swal.fire({
  //     title: 'Are you sure to Delete?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#0C0833',
  //     cancelButtonColor: 'grey',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {

  //     if (result.value) {
  //       console.log(result)
  //       handleDeleteSocialMedia(id);
  //     }
  //   });
  // };




  // 


  return (
    <>
      <div className='profile-page'>
        <Helmet>
          <title>Profile</title>
        </Helmet>
        <section className="wrapper _profile">
          <div className="row">
            <ToastContainer />
            <div className="col-lg-12">
              <span className='p-3 fw-bold'>Settings</span>
            </div>
          </div>
        </section>
        <section className='profile-s card mt-3'>
          <div className='profile-d' >
            <ToastContainer />
            <Form className='row m-2' onSubmit={handleSubmit(onSubmit)}>


              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="name@gmail.com" id="email" value={formData.email}
                  {...register("Email", {
                    required: false,
                    onChange: (e) => handleInputChange('email', e.target.value)
                  })}
                />

                {errors.Email && (
                  <div className='errMsg text-danger'>Email is Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Map</Form.Label>
                <Form.Control type="text" placeholder="map url" value={formData.mapUrl}
                  {...register("mapUrl", {
                    required: false,
                    onChange: (e) => handleInputChange('mapUrl', e.target.value)
                  })}
                />

                {errors.Email && (
                  <div className='errMsg text-danger'>Email is Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Label>Logo</Form.Label>
                    <Form.Control type="file" id="logo"
                      {...register("logo", {
                        required: false,
                        onChange: (e) => handleInputChange('logo', e.target.files[0])
                      })}
                    />

                    {errors.logo && (
                      <div className='errMsg text-danger'>Please Select Logo</div>
                    )}
                  </div>
                  <div className='col-lg-6 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3' style={{ background: '#0a0b3130' }}>

                      {!formData.logo ?

                        <img src={`${apiUrl}/static/assets/${logo}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                        :
                        <img src={URL.createObjectURL(formData.logo)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                      }
                    </div>
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Label>Logo white</Form.Label>
                    <Form.Control type="file" id="white_logo"
                      {...register("white_logo", {
                        required: false,
                        onChange: (e) => handleInputChange('logoWhite', e.target.files[0])
                      })}
                    />

                    {errors.white_logo && (
                      <div className='errMsg text-danger'>Please Select White Logo</div>
                    )}
                  </div>
                  <div className='col-lg-6 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3' style={{ background: '#0a0b3130' }}>

                      {!formData.logoWhite ?

                        <img src={`${apiUrl}/static/assets/${logoWhite}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                        :
                        <img src={URL.createObjectURL(formData.logoWhite)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                      }
                    </div>
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Facebook Url</Form.Label>
                <Form.Control type="text" placeholder="facebook url" value={formData.facebookUrl}
                  {...register("facebookUrl", {
                    required: false,
                    onChange: (e) => handleInputChange('facebookUrl', e.target.value)
                  })}
                />

                {errors.facebookUrl && (
                  <div className='errMsg text-danger'>Facebook Url Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Twitter Url</Form.Label>
                <Form.Control type="text" placeholder="twitter url" value={formData.twitterUrl}
                  {...register("twitterUrl", {
                    required: false,
                    onChange: (e) => handleInputChange('twitterUrl', e.target.value)
                  })}
                />

                {errors.twitterUrl && (
                  <div className='errMsg text-danger'>Twitter Url Required</div>
                )}

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Youtube Url</Form.Label>
                <Form.Control type="text" placeholder="youtube url" value={formData.youtubeUrl}
                  {...register("youtubeUrl", {
                    required: false,
                    onChange: (e) => handleInputChange('youtubeUrl', e.target.value)
                  })}
                />

                {errors.youtubeUrl && (
                  <div className='errMsg text-danger'>Youtube Url Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Instagram Url</Form.Label>
                <Form.Control type="text" placeholder="instagram url" value={formData.instagramUrl}
                  {...register("instagramUrl", {
                    required: false,
                    onChange: (e) => handleInputChange('instagramUrl', e.target.value)
                  })}
                />

                {errors.instagramUrl && (
                  <div className='errMsg text-danger'>Instagram Url Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>LinkedIn Url</Form.Label>
                <Form.Control type="text" placeholder="linkedIn url" value={formData.linkedInUrl}
                  {...register("linkedInUrl", {
                    required: false,
                    onChange: (e) => handleInputChange('linkedInUrl', e.target.value)
                  })}
                />

                {errors.linkedInUrl && (
                  <div className='errMsg text-danger'>linkedIn Url Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Threads</Form.Label>
                <Form.Control type="text" placeholder="threads" value={formData.threads}
                  {...register("threads", {
                    required: false,
                    onChange: (e) => handleInputChange('threads', e.target.value)
                  })}
                />

                {errors.threads && (
                  <div className='errMsg text-danger'>Threads Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Address</Form.Label>
                <Form.Control as="textarea" placeholder="Address" id="address" value={formData.address}
                  {...register("address", {
                    required: false,
                    onChange: (e) => handleInputChange('address', e.target.value)
                  })}
                />

                {errors.address && (
                  <div className='errMsg text-danger'>Address is Required</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Whatsapp</Form.Label>
                <Form.Control type="text" placeholder="whatsapp" value={formData.whatsapp}
                  {...register("whatsapp", {
                    required: false,
                    onChange: (e) => handleInputChange('whatsapp', e.target.value)
                  })}
                />

                {errors.whatsapp && (
                  <div className='errMsg text-danger'>Whatsapp Required</div>
                )}

              </Form.Group>



              {/*  */}

              <hr></hr>
              <span className='my-3 fw-bold'>SMTP Configuration </span>
              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>SMTP Email</Form.Label>
                <Form.Control type="email" placeholder="name@gmail.com" value={formData.smtp_email}
                  {...register("smtp_email", {
                    required: false,
                    onChange: (e) => handleInputChange('smtp_email', e.target.value)
                  })}
                />

                {errors.smtp_email && (
                  <div className='errMsg text-danger'>SMTP Email is Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>SMTP Password</Form.Label>
                <Form.Control type="password" placeholder="smtp_password" value={formData.smtp_password} autoComplete='new-password'
                  {...register("smtp_password", {
                    required: false,
                    onChange: (e) => handleInputChange('smtp_password', e.target.value)
                  })}
                />

                {errors.smtp_password && (
                  <div className='errMsg text-danger'>SMTP Password is Required</div>
                )}

              </Form.Group>
              <hr></hr>
              <span className='my-3 fw-bold'>ReCaptcha Configuration </span>
              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Site Key</Form.Label>
                <Form.Control type="text" value={formData.site_key}
                  {...register("site_key", {
                    required: false,
                    onChange: (e) => handleInputChange('site_key', e.target.value)
                  })}
                />

                {errors.site_key && (
                  <div className='errMsg text-danger'>Site Key is Required</div>
                )}

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Site Secret</Form.Label>
                <Form.Control type="text" value={formData.site_secret}
                  {...register("site_secret", {
                    required: false,
                    onChange: (e) => handleInputChange('site_secret', e.target.value)
                  })}
                />

                {errors.site_secret && (
                  <div className='errMsg text-danger'>Site Secret is Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Blocked_emails</Form.Label>
                <Form.Control type="text" placeholder="blocked1@example\\.com, blocked2@example\\.com" value={formData.blocked_emails}
                  {...register("blocked_emails", {
                    required: false,
                    onChange: (e) => handleInputChange('blocked_emails', e.target.value)
                  })}
                />

                {/* {errors.blocked_emails && (
                  <div className='errMsg text-danger'>Blocked_emails Required</div>
                )} */}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>Domains</Form.Label>
                <Form.Control type="text" placeholder="au, uk" value={formData.domains}
                  {...register("domains", {
                    required: false,
                    onChange: (e) => handleInputChange('domains', e.target.value)
                  })}
                />

                {/* {errors.blocked_emails && (
                  <div className='errMsg text-danger'>Domains Required</div>
                )} */}

              </Form.Group>
              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white' variant='contained'>
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </section>





        <div className="col-lg-12 mt-3">
          <span className='mt-3 fw-bold'>Phone </span>
        </div>
        <section className='profile-s card mt-1'>
          <div className='row profile-d' >

            <div className='prev-numbers m-3'>
              <Form.Label>Phone Preview</Form.Label>
              <Stack direction='row' className="d-flex flex-wrap gap-3">
                {phoneNumbers?.map((item, index) => (

                  <Chip className='px-2 py-3' key={index}
                    label={item.phoneNumber}
                    avatar={<img alt={item.phoneIcon} src={`${apiUrl}/static/assets/${item.phoneIcon}`} />}
                    onDelete={() => confirmDeletePhone(item._id)}
                    deleteIcon={<FaTrash className='text-danger' style={{ marginLeft: '20px' }} />}
                    variant="outlined"
                  />

                ))}
              </Stack>
            </div>

            <Form className='row m-2' onSubmit={handleSubmit1(onSubmitPhone)}>
              <span className='my-3 fw-bold'>Add Phone </span>
              <Form.Group className="mb-3 col-lg-4 col-md-6 col-sm-12 col-12">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="text" placeholder="9991234567" value={phoneNumber}
                  {...register1("Phone", {
                    required: false,
                    onChange: (e) => setPhoneNumber(e.target.value)
                  })}
                />

                {errors1.Phone && (
                  <div className='errMsg text-danger'>Enter Phone Number</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Label>Country Icon</Form.Label>
                    <Form.Control type="file"
                      {...register1("phoneIcon", {
                        required: false,
                        onChange: (e) => setPhoneIcon(e.target.files[0])
                      })}
                    />

                    {errors1.phoneIcon && (
                      <div className='errMsg text-danger'>Please Select Phone Icon</div>
                    )}
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-12 col-12 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>
                      <img src={phoneIcon == "" ? NoImg : URL.createObjectURL(phoneIcon)} style={{ maxWidth: "300px", maxHeight: "300px", width: '100px' }} />
                    </div>
                  </div>
                </div>
              </Form.Group>


              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white' variant='contained'>
                  Add Phone
                </Button>
              </div>
            </Form>
          </div>
        </section >


        {/* <div className="col-lg-12 mt-3">
          <span className='mt-3 fw-bold'>Social Media </span>
        </div>
        <section className='profile-s card mt-1'>
          <div className='row profile-d' >

            <div className='social-media m-3'>
              <Form.Label>Social Media Preview</Form.Label>
              <Stack direction='row' className="d-flex flex-wrap gap-3">
                {socialMedia?.map((item, index) => (

                  <Chip className='px-2 py-3'
                    label={item.profileLink}
                    avatar={<img alt={item.platform} src={`${apiUrl}/static/assets/${item.plateformIcon}`} />}
                    onDelete={() => confirmDeleteSocialMedia(item._id)}
                    deleteIcon={<FaTrash className='text-danger' style={{ marginLeft: '20px' }} />}
                    variant="outlined"
                  />

                ))}
              </Stack>
            </div>



            <Form className='row m-2' onSubmit={handleSubmit2(onSubmitSocialMedia)}>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-6 col-6">
                <Form.Label>Platform</Form.Label>
                <Form.Control type="text" placeholder="Platform" value={platform}
                  {...register2("platform", {
                    required: false,
                    onChange: (e) => setPlatform(e.target.value)
                  })}
                />

                {errors2.platform && (
                  <div className='errMsg text-danger'>Enter PlateForm </div>
                )}

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-6 col-6">
                <Form.Label>Profile Link</Form.Label>
                <Form.Control type="url" placeholder="https://www.instagram.com/" value={profileLink}
                  {...register2("profileLink", {
                    required: false,
                    onChange: (e) => setProfileLink(e.target.value)
                  })}
                />

                {errors2.profileLink && (
                  <div className='errMsg text-danger'>Enter profile link</div>
                )}

              </Form.Group>



              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className='row'>
                  <div className='col-lg-6'>
                    <Form.Label>PlateForm  Icon</Form.Label>
                    <Form.Control type="file"
                      {...register2("platformIcon", {
                        required: false,
                        onChange: (e) => setPlatformIcon(e.target.files[0])
                      })}
                    />

                    {errors2.platformIcon && (
                      <div className='errMsg text-danger'>Please Select PlateForm Icon</div>
                    )}
                  </div>
                  <div className='col-lg-6 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>
                      <img src={platformIcon == "" ? NoImg : URL.createObjectURL(platformIcon)} style={{ maxWidth: "300px", maxHeight: "300px", width: '100px' }} />
                    </div>
                  </div>
                </div>
              </Form.Group>

              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white' variant='contained'>
                  Add
                </Button>
              </div>
            </Form>
          </div>
        </section> */}
      </div >
    </>
  );
};

export default Setting;

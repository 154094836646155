import React, { useState, useEffect, Component } from 'react';
import Datatables from '../DataTable/Datatables';
import { FaShareSquare } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { Axios } from '../components/common/Axios';
import Card from 'react-bootstrap/Card';
import Swal from "sweetalert2";
import { notify, notifyErr } from '../components/common/norify';
import EnquiryModel from '../components/common/EnqueryModel';

const Enquiry = () => {

    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [pending, setPending] = useState(true);
    const [id, setId] = useState('')
    const [showUpdate, setShowUpdate] = useState(false);
    const [filterBy, setFilterBy] = useState('0')

    const handleShowUpdate = (row) => {
        setShowUpdate(true);
        setId(row._id);
    };



    const getData = async () => {
        try {
            const enquiryData = await Axios.get('/api/contact/enquiry');
            let enquiry = enquiryData.data.data;
            setData(enquiry);
            setNewData(enquiry)

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        } finally {
            setPending(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);


    // const { register, handleSubmit, reset, formState: { errors: errors } } = useForm();
    // const onSubmit = async () => {
    //     try {
    //         const response = await Axios.put(`/api/page/${id}`, {},
    //             {
    //                 headers: {
    //                     'Content-Type': 'application/x-www-form-urlencoded',
    //                 },
    //             });
    //         if (response.status === 200 || 201) {
    //             notify('SEO Updated Successfully');
    //             handleClose();
    //             setTimeout(() => {
    //                 window.location.reload(false);
    //             }, 1500);
    //         }
    //     } catch (error) {
    //         notifyErr('Something went wrong')
    //         console.error(error);
    //     } finally {
    //         setPending(false)
    //     }

    // }

    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '100px',
            },
            {
                name: <th className='fs-6 '>Name</th>,
                selector: (row) => row.name,
                width: '200px',
            },
            {
                name: <th className='fs-6'>Email</th>,
                selector: (row) => row.email,
                width: '220px',
            },
            {
                name: <th className='fs-6'>Phone</th>,
                selector: (row) => row.phone,
                width: '220px',
            },
            {
              name: <th className='fs-6'>Date</th>,
              selector: (row) => <span>{new Date(row.createdAt).toString()}</span>,

            },
            // {
            //     name: <th className='fs-6'>Status</th>,
            //     cell: (row, index) => row.status == 0 ? <span className='bg-info text-white p-1 rounded'>Pending</span> : row.status == 1 ? <span className=' bg-success text-white p-1 rounded'>Complete</span> : '-',
            //     width: '220px',
            // },
            {
                name: <th className='fs-6'>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>

                        <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}
                            onClick={() => handleShowUpdate(row)}
                        >
                            <FaShareSquare />
                        </Button>
                    </div>
                ),
                right: true

            },
        ],
    };



    useEffect(() => {
        setData(newData?.filter(data => data.status == 0))
    }, [newData])




    // const filterByStatus = (status) => {
    //     if (status == "") {
    //         setData(newData);
    //     } else {
    //         setData(newData?.filter(data => data.status == status));
    //     }
    //     setFilterBy(status)
    // }

    return (
        <>
            <div className='enquiry-page'>
                <Helmet>
                    <title>Enquiry</title>
                </Helmet>

                <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                    <ToastContainer />
                    <section className="wrapper _blogs">
                        <div className="row">
                            <div className="col-lg-6">
                                <span className='fw-bold'>Enquiries</span>
                                {/* <div>
                                    <strong >Status :-</strong>
                                    <select className="status select_status filter"
                                        value={filterBy}
                                        onChange={(e) => filterByStatus(e.target.value)}
                                    >
                                        <option value="">All</option>
                                        <option value="0">Pending</option>
                                        <option value="1">Complete</option>


                                    </select>
                                </div> */}
                            </div>

                            <div className='col-lg-6 pb-2 btn-d d-flex justify-content-end'>
                                {/* <Link to="/service-add">
                                    <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right' }} >
                                        Add Service
                                    </Button>
                                </Link> */}

                            </div>
                        </div>
                    </section>

                </section>

                <section className=' mt-2'>
                    <Card >
                        <div className="main " >
                            <Datatables data={data} columns={tableData.columns} pending={pending} />
                        </div>
                    </Card>

                </section>

            </div>

            <EnquiryModel id={id} show={showUpdate} setShowUpdate={setShowUpdate} />

        </>
    )
}

export default Enquiry
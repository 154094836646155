import React, { useState, useEffect } from 'react';
import Datatables from '../DataTable/Datatables';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Axios } from '../components/common/Axios';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import { notify, notifyErr } from '../components/common/norify';
import Select from 'react-select';


const PopupModel = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [services, setServices] = useState([]);
    const [subservices, setSubServices] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false)
    const [id, setId] = useState('');
    const [pages, setPages] = useState('');
    const [selection, setSelection] = useState('');
    // const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        image: '',
        type: '',
        btn_text: '',
        btn_link: '',
        delay: '',
        pages: ''

    })
    const [image, setImage] = useState('');
    const [updateFormData, setUpdateFormData] = useState({
        title: '',
        content: '',
        image: '',
        type: '',
        btn_text: '',
        btn_link: '',
        delay: '',
        pages: ''

    })

    const handleShow = (row) => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setFormData({
            title: '',
            content: '',
            image: '',
            type: '',
            btn_text: '',
            btn_link: '',
            delay: '',
            pages: ''
        })
        reset({
            title: '',
            content: '',
            image: '',
            type: '',
            btn_text: '',
            btn_link: '',
            delay: '',
            pages: ''
        })
    };


    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setUpdateFormData({
            title: '',
            content: '',
            image: '',
            type: '',
            btn_text: '',
            btn_link: '',
            delay: '',
            pages: ''
        })
        reset1({
            title: '',
            content: '',
            image: '',
            type: '',
            btn_text: '',
            btn_link: '',
            delay: '',
            pages: ''
        })
        setId('');
    }
    const handleShowUpdate = (row) => {
        setShowUpdate(true)
        setId(row._id)
        getPopup(row._id);
    }

    const getData = async () => {
        try {
            const popupData = await Axios.get(`api/popup`);
            let popup = popupData.data.data;
            setData(popup);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        } finally {
            setPending(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);


    const getData1 = async () => {
        try {
            const response = await Axios.get(`/api/getIdName`);
            const { services, subservices, pages } = response.data.data;
            const combinedOptions = [
                ...services.map(service => ({ value: `${service._id}`, label: service.name })),
                ...subservices.map(subservice => ({ value: `${subservice._id}`, label: subservice.name })),
                ...pages.map(page => ({ value: `${page._id}`, label: page.name }))
            ];
            setOptions(combinedOptions);
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        }
    };

    useEffect(() => {
        getData1();
    }, []);

    const selectedOption = Array.from(new Set(data.flatMap(item => item.pages)));
    const notSelectedOptions = options.filter(option => !selectedOption.includes(option.value?.toString()));
    // console.log(notSelectedOptions,selectedOption);

    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    const handleInputChange1 = (fieldName, value) => {
        setUpdateFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    const handleChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
        const selectedValues = selectedOptions.map(option => option.value);
        const pagesString = selectedValues.toString();
        handleInputChange('pages', pagesString);
    };

    const handleChange1 = (selectedOptions) => {
        // setSelectedOptions(selectedOptions);
        const selectedValues = selectedOptions.map(option => option.value);
        //const pagesString = selectedValues.toString(); 
        handleInputChange1('pages', selectedValues);
    };


    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '150px',
            },
            {
                name: <th className='fs-6 '>Title</th>,
                selector: (row) => row.title,
                width: '250px',
            },
            {
                name: <th className='fs-6 '>Content</th>,
                selector: (row) => row.content,
                width: '250px',
            },

            {
                name: <th className='fs-6 '>Image</th>,
                selector: (row) => (
                    <img width="60px" height="60px" className='p-2' src={`${apiUrl}/static/assets/${row.image}`} alt="Portfolio Image" />
                ),
                width: '200px',
            },
            {
                name: <th className='fs-6 '>delay</th>,
                selector: (row) => row.delay,
                width: '200px',
            },
            // {
            //     name: <th className='fs-6 '>isform</th>,
            //     selector: (row) => row.text,
            //     width: '300px',
            // },
            // {
            //     name: <th className='fs-6 '>btn_text</th>,
            //     selector: (row) => row.text,
            //     width: '300px',
            // },
            // {
            //     name: <th className='fs-6 '>btn_link</th>,
            //     selector: (row) => row.text,
            //     width: '300px',
            // },
            {
                name: <th className='fs-6 '>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>

                        <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}
                            onClick={() => handleShowUpdate(row)}
                        >
                            <FaEdit />
                        </Button>

                        <Button className='_delete_btn_ text-white  bg-danger' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}
                            onClick={() => confirmDelete(row._id)}
                        ><FaTrashAlt /></Button>
                    </div>
                ),
                right: true,
            },
        ],
    };

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = async () => {
        setPending(true)
        try {
            const response = await Axios.post('/api/popup', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Popup added Successfully');
                handleClose();
                setTimeout(() => {
                    window.location.reload(false);
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
            console.log(error)
        } finally {
            setPending(false)
        }

    };

    const { register: register1, handleSubmit: handleSubmit1, reset: reset1, formState: { errors: errors1 } } = useForm();
    const getPopup = async (id) => {
        try {
            const popupData = await Axios.get(`/api/popup/${id}`);
            let popup = popupData.data;
            if (popup) {
                setUpdateFormData({
                    title: popup.title,
                    content: popup.content,
                    type: popup.type,
                    btn_text: popup.btn_text,
                    btn_link: popup.btn_link,
                    delay: popup.delay,
                    pages: popup.pages
                })
                setImage(popup.image);
            }
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    };



    const onUpdate = async () => {
        setPending(true);
        try {
            const response = await Axios.put(`/api/popup/${id}`, updateFormData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Popup updated Successfully');
                // handleCloseUpdate();
                setTimeout(() => {
                    window.location.reload(false);
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        } finally {
            setPending(false)
        }
    };
    const handleDelete = async (id) => {
        try {
            const response = await Axios.delete(`/api/popup/${id}`);
            if (response.status === 200) {
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            }
            Swal.fire({
                title: 'Deleted!',
                text: 'Your data has been deleted.',
                icon: 'success',
                confirmButtonColor: '#0C0833',
            });
        } catch (error) {
            console.error(error);

            Swal.fire({
                title: 'Error',
                text: 'An error occurred while deleting the data.',
                icon: 'error',
            });
        }
    };

    const confirmDelete = (id) => {
        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0C0833',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {

            if (result.value) {
                console.log(result)
                handleDelete(id);
            }
        });
    };




    return (
        <>
            <div className='profile-page'>
                <Helmet>
                    <title>Popup</title>
                </Helmet>
                <section className="wrapper _blogs">
                    <div className="row">
                        <div className="col-lg-6">
                            <span className='p-3 fw-bold'>Popup</span>
                        </div>
                        <div className='col-lg-6 px-2 btn-d '>

                            <div className=' pb-2 btn-d d-flex justify-content-end'>

                                <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right' }}
                                    onClick={handleShow}
                                >
                                    Add Popup
                                </Button>

                            </div>
                        </div>
                    </div>
                </section>

                <section className='mt-2'>
                    <Card>
                        <div className="main" >
                            <Datatables data={data} columns={tableData.columns} />
                        </div>
                    </Card>
                </section>


            </div>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose} className='' style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>

                    <Modal.Title>Add Popup</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Title" id="title" value={formData.title}
                            {...register("Title", {
                                required: true,
                                onChange: (e) => handleInputChange('title', e.target.value)
                            })}
                        />

                        {errors.Name && (
                            <div className='errMsg text-danger'>Title is required</div>
                        )}

                    </Form.Group>


                    <Form.Group
                        className="mb-3"

                    >

                        <div className='row'>
                            <div className='col-12'>
                                <Form.Label>Image</Form.Label>
                                <Form.Control type="file"
                                    {...register("Image", {
                                        required: true,
                                        onChange: (e) => handleInputChange('image', e.target.files[0])
                                    })}
                                />

                                {errors.Image && (
                                    <div className='errMsg text-danger'>Please Select Image</div>
                                )}

                            </div>

                            <div className='col-12 text-center mb-3 mt-3'>
                                <Form.Label>Image Preview</Form.Label>
                                <div className='p-3'>

                                    {formData.image == "" ?

                                        <img src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTULSPiQKGEcCtCxrkr4t9Ub8U-Jwzv3kXu2RMOzQoihg&s`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                        :
                                        <img src={URL.createObjectURL(formData.image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                    }
                                </div>
                            </div>
                        </div>

                    </Form.Group>


                    <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label>Content</Form.Label>
                        <Form.Control as="textarea" rows={4} placeholder="Content" id="content" value={formData.content}
                            {...register("content", {
                                required: true,
                                onChange: (e) => handleInputChange('content', e.target.value)
                            })}
                        />
                    </Form.Group>

                    <Form.Group className="mt-2 mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label htmlFor="Type">Type</Form.Label>
                        <Form.Select
                            id="type"
                            aria-label="Default select example"
                            value={formData.type}
                            {...register("type", {
                                required: true,
                                onChange: (e) => handleInputChange('type', e.target.value)
                            })}

                        >
                            <option value="" >Select Type</option>
                            <option value="form">Form</option>
                            <option value="button">Button</option>


                        </Form.Select>
                        {errors.isform && (
                            <div className='errMsg text-danger'>type is required</div>
                        )}
                    </Form.Group>
                    {formData.type === "button" && (
                        <Form.Group className="mb-3">
                            <Form.Label>Btn_Text</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Text"
                                value={formData.btn_text}
                                onChange={(e) => handleInputChange('btn_text', e.target.value)}
                            />
                            <Form.Label>Btn_Link</Form.Label>
                            <Form.Control
                                type="link"
                                placeholder="link"
                                value={formData.btn_link}
                                onChange={(e) => handleInputChange('btn_link', e.target.value)}
                            />
                        </Form.Group>

                    )}
                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Delay</Form.Label>
                        <Form.Control type="delay" placeholder="delay" id="delay" value={formData.delay}
                            {...register("delay", {
                                required: true,
                                onChange: (e) => handleInputChange('delay', e.target.value)
                            })}
                        />

                        {errors.Delay && (
                            <div className='errMsg text-danger'>Delay is required</div>
                        )}

                    </Form.Group>

                    {/* <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label htmlFor="selection">Pages</Form.Label>
                        <Form.Select
                            
                            id="pages"
                            aria-label="Select Pages"
                            value={formData.pages}
                            
                            // {...register("pages", { required: true })}
                            onChange={(e) => handleInputChange('pages', e.target.value)}
                        >
                            <option value="">Select Pages</option>
        
                            {options.map((option, index) => (
                                <option value={option.value} key={index}>{option.label}</option>
                            ))}
                        </Form.Select>
                        {error && (
                            <div className='errMsg text-danger'>{error}</div>
                        )}
                    </Form.Group> */}

                    <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">

                        <Form.Label htmlFor="selection">Pages</Form.Label>
                        <Select
                            id="pages"
                            // value={selectedOption}
                            // onChange={handleSelectChange}
                            // options={options.filter(option => option.value !== (selectedOption && selectedOption.value))}
                            options={notSelectedOptions}
                            // options={options.filter(option => !selectedOptions.includes(option))}
                            value={options.filter(option => formData.pages.includes(option.value))}
                            isMulti
                            onChange={handleChange}
                        />
                        {error && (
                            <div className='errMsg text-danger'>{error}</div>
                        )}


                    </Form.Group>






                    {/* <Form.Group className="mb-3 col-12">
                        <Form.Label>Btn_Text</Form.Label>
                        <Form.Control type="text" placeholder="btn_text" value={formData.btn_text}
                            {...register("btn_text", {
                                required: true,
                                onChange: (e) => handleInputChange('btn_text', e.target.value)
                            })}
                        />

                        {errors.Position && (
                            <div className='errMsg text-danger'>Btn_Text is required</div>
                        )}

                    </Form.Group>

                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Btn_Link</Form.Label>
                        <Form.Control type="text" placeholder="Btn_Link" value={formData.btn_link}
                            {...register("Btn_Link", {
                                required: true,
                                onChange: (e) => handleInputChange('btn_link', e.target.value)
                            })}
                        />

                        {errors.Btn_Link && (
                            <div className='errMsg text-danger'>Btn_Link is required</div>
                        )}

                    </Form.Group> */}






                    <Modal.Footer>
                        <Button type="submit" variant="contained" className='text-white' style={{ backgroundColor: '#0C0833' }}  >
                            Add
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


            {/* update */}
            <Modal show={showUpdate} onHide={handleCloseUpdate} className='' style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>

                    <Modal.Title>Update Popup</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit1(onUpdate)}>
                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Title" id="title" value={updateFormData.title}
                            {...register1("updateTitle", {
                                required: false,
                                onChange: (e) => handleInputChange1('title', e.target.value)
                            })}
                        />

                        {errors1.Title && (
                            <div className='errMsg text-danger'>Title is required</div>
                        )}

                    </Form.Group>



                    <Form.Group
                        className="mb-3"

                    >

                        <div className='row'>
                            <div className='col-12'>
                                <Form.Label>Image</Form.Label>
                                <Form.Control type="file"
                                    {...register1("updateImage", {
                                        required: false,
                                        onChange: (e) => handleInputChange1('image', e.target.files[0])
                                    })}
                                />

                                {errors1.Image && (
                                    <div className='errMsg text-danger'>Please Select Image</div>
                                )}

                            </div>

                            <div className='col-12 text-center mb-3 mt-3'>
                                <Form.Label>Image Preview</Form.Label>
                                <div className='p-3'>

                                    {!updateFormData.image ?

                                        <img src={`${apiUrl}/static/assets/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                        :
                                        <img src={URL.createObjectURL(updateFormData.image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                    }
                                </div>
                            </div>
                        </div>

                    </Form.Group>


                    <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label>Content</Form.Label>
                        <Form.Control as="textarea" rows={4} placeholder="Content" id="content" value={updateFormData.content}
                            {...register1("updateContent", {
                                required: false,
                                onChange: (e) => handleInputChange1('content', e.target.value)
                            })}
                        />
                    </Form.Group>

                    <Form.Group className="mt-2 mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label htmlFor="Type">Type</Form.Label>
                        <Form.Select
                            id="type"
                            aria-label="Default select example"
                            value={updateFormData.type}
                            {...register("type", {
                                required: true,
                                onChange: (e) => handleInputChange1('type', e.target.value)
                            })}

                        >
                            <option value="" >Select Type</option>
                            <option value="1">Form</option>
                            <option value="2">Button</option>


                        </Form.Select>
                        {errors.isform && (
                            <div className='errMsg text-danger'>type is required</div>
                        )}
                    </Form.Group>
                    {updateFormData.type === "2" && (
                        <Form.Group className="mb-3">
                            <Form.Label>Btn_Text</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Text"
                                value={updateFormData.btn_text}
                                onChange={(e) => handleInputChange1('btn_text', e.target.value)}
                            />
                            <Form.Label>Btn_Link</Form.Label>
                            <Form.Control
                                type="link"
                                placeholder="link"
                                value={updateFormData.btn_link}
                                onChange={(e) => handleInputChange1('btn_link', e.target.value)}
                            />
                        </Form.Group>

                    )}
                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Delay</Form.Label>
                        <Form.Control type="delay" placeholder="delay" id="delay" value={updateFormData.delay}
                            {...register("delay", {
                                required: true,
                                onChange: (e) => handleInputChange1('delay', e.target.value)
                            })}
                        />

                        {errors.Delay && (
                            <div className='errMsg text-danger'>Delay is required</div>
                        )}

                    </Form.Group>

                    {/* <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label htmlFor="selection">Pages</Form.Label>
                        <Form.Select
                            id="pages"
                            aria-label="Select"
                            value={updateFormData.pages}
                            {...register("pages", {
                                required: true,
                                onChange: (e) => handleInputChange1('pages', e.target.value)
                            })}
                        >
                            <option value="">Select Pages</option>
                            {options.map((option, index) => (
                                <option value={option.value} key={index}>{option.label}</option>
                            ))}
                        </Form.Select>
                        {error && (
                            <div className='errMsg text-danger'>{error}</div>
                        )}
                    </Form.Group> */}

                    <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">

                        <Form.Label htmlFor="selection">Pages</Form.Label>
                        <Select
                            id="pages"
                            options={notSelectedOptions}
                            // options={options.filter(option => !selectedOptions.includes(option))}
                            value={options.filter(option => updateFormData.pages.includes(option.value))}
                            isMulti
                            onChange={handleChange1}
                        />
                        {error && (
                            <div className='errMsg text-danger'>{error}</div>
                        )}


                    </Form.Group>


                    {/* <Form.Group className="mb-3 col-12">
                        <Form.Label>Btn_Text</Form.Label>
                        <Form.Control type="text" placeholder="Btn_Text" value={updateFormData.btn_text}
                            {...register1("updateBtn_Text", {
                                required: false,
                                onChange: (e) => handleInputChange1('btn_text', e.target.value)
                            })}
                        />

                        {errors1.Btn_Text && (
                            <div className='errMsg text-danger'>Btn_Text is required</div>
                        )}

                    </Form.Group>

                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Btn_Link</Form.Label>
                        <Form.Control type="text" placeholder="Btn_Link" value={updateFormData.btn_link}
                            {...register1("updateBtn_Text", {
                                required: false,
                                onChange: (e) => handleInputChange1('btn_text', e.target.value)
                            })}
                        />

                        {errors1.Btn_Text && (
                            <div className='errMsg text-danger'>Btn_Text is required</div>
                        )}

                    </Form.Group> */}


                    {/* <div className='btn-d d-flex justify-content-center'>
                        <Button type='submit' className='text-white' variant='contained'>
                            Update Testimonials
                        </Button>
                    </div> */}
                    <Modal.Footer>
                        <Button type="submit" variant="contained" className='text-white' style={{ backgroundColor: '#0C0833' }}  >
                            Update
                        </Button>
                        <Button variant="secondary" onClick={handleCloseUpdate}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}

export default PopupModel
import React, { useState, useEffect } from 'react';
import Datatables from '../DataTable/Datatables';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Axios } from '../components/common/Axios';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import { notify, notifyErr } from '../components/common/norify';


const Testimonials = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false)
    const [id, setId] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        image: '',
        position: '',
        content: '',
        rating: ''
    })
    const [image, setImage] = useState('');
    const [updateFormData, setUpdateFormData] = useState({
        name: '',
        image: '',
        position: '',
        content: '',
        rating: '',
    })

    const handleShow = (row) => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setFormData({
            name: '',
            image: '',
            position: '',
            content: '',
            rating: ''
        })
        reset({
            name: '',
            image: '',
            position: '',
            content: '',
            rating: ''
        })
    };


    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setUpdateFormData({
            name: '',
            image: '',
            position: '',
            content: '',
            rating: ''
        })
        reset1({
            name: '',
            image: '',
            position: '',
            content: '',
            rating: ''
        })
        setId('');
    }
    const handleShowUpdate = (row) => {
        setShowUpdate(true)
        setId(row._id)
        getTestimonial(row._id);
    }

    const getData = async () => {
        try {
            const testimonialData = await Axios.get(`api/testimonial`);
            let testimonial = testimonialData.data.data;
            setData(testimonial);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        } finally {
            setPending(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };

    const handleInputChange1 = (fieldName, value) => {
        setUpdateFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };


    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '150px',
            },
            {
                name: <th className='fs-6 '>Name</th>,
                selector: (row) => row.name,
                width: '250px',
            },
            {
                name: <th className='fs-6 '>Position</th>,
                selector: (row) => row.position,
                width: '300px',
            },

            {
                name: <th className='fs-6 '>Image</th>,
                selector: (row) => (
                    <img width="60px" height="60px" className='p-2' src={`${apiUrl}/static/assets/${row.image}`} alt="Portfolio Image" />
                ),
                width: '200px',
            },
            {
                name: <th className="fs-6">Rating</th>,
                selector: (row) => row.rating,
                width: "300px",
            },
            {
                name: <th className='fs-6 '>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>

                        <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}
                            onClick={() => handleShowUpdate(row)}
                        >
                            <FaEdit />
                        </Button>

                        <Button className='_delete_btn_ text-white  bg-danger' variant='contained' style={{ float: 'right', backgroundColor: '#0C0833' }}
                            onClick={() => confirmDelete(row._id)}
                        ><FaTrashAlt /></Button>
                    </div>
                ),
                right: true,
            },
        ],
    };

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = async () => {
        setPending(true)
        try {
            const response = await Axios.post('/api/testimonial', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Testimonial added Successfully');
                // handleClose();
                setTimeout(() => {
                    window.location.reload(false);
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        } finally {
            setPending(false)
        }

    };

    const { register: register1, handleSubmit: handleSubmit1, reset: reset1, formState: { errors: errors1 } } = useForm();
    const getTestimonial = async (id) => {
        try {
            const testimonialData = await Axios.get(`/api/testimonial/${id}`);
            let testimonial = testimonialData.data;
            if (testimonial) {
                setUpdateFormData({
                    name: testimonial.name,
                    position: testimonial.position,
                    content: testimonial.content,
                    rating: testimonial.rating,
                })
                setImage(testimonial.image);
            }
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    };

    const onUpdate = async () => {
        setPending(true);
        try {
            const response = await Axios.put(`/api/testimonial/${id}`, updateFormData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Testimonial updated Successfully');
                // handleCloseUpdate();
                setTimeout(() => {
                    window.location.reload(false);
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        } finally {
            setPending(false)
        }
    };


    const handleDelete = async (id) => {
        try {
            const response = await Axios.delete(`/api/testimonial/${id}`);
            if (response.status === 200) {
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            }
            Swal.fire({
                title: 'Deleted!',
                text: 'Your data has been deleted.',
                icon: 'success',
                confirmButtonColor: '#0C0833',
            });
        } catch (error) {
            console.error(error);

            Swal.fire({
                title: 'Error',
                text: 'An error occurred while deleting the data.',
                icon: 'error',
            });
        }
    };

    const confirmDelete = (id) => {
        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0C0833',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {

            if (result.value) {
                console.log(result)
                handleDelete(id);
            }
        });
    };




    return (
        <>
            <div className='profile-page'>
                <Helmet>
                    <title>Testimonials</title>
                </Helmet>
                <section className="wrapper _blogs">
                    <div className="row">
                        <div className="col-lg-6">
                            <span className='p-3 fw-bold'>Testimonials</span>
                        </div>
                        <div className='col-lg-6 px-2 btn-d '>

                            <div className=' pb-2 btn-d d-flex justify-content-end'>

                                <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right' }}
                                    onClick={handleShow}
                                >
                                    Add Testimonials
                                </Button>

                            </div>
                        </div>
                    </div>
                </section>

                <section className='mt-2'>
                    <Card>
                        <div className="main" >
                            <Datatables data={data} columns={tableData.columns} />
                        </div>
                    </Card>
                </section>


            </div>

            {/* Add Modal */}
            <Modal show={show} onHide={handleClose} className='' style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>

                    <Modal.Title>Add Testimonial</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Title" id="name" value={formData.name}
                            {...register("Name", {
                                required: true,
                                onChange: (e) => handleInputChange('name', e.target.value)
                            })}
                        />

                        {errors.Name && (
                            <div className='errMsg text-danger'>Name is required</div>
                        )}

                    </Form.Group>

                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Position</Form.Label>
                        <Form.Control type="text" placeholder="Position" value={formData.position}
                            {...register("Position", {
                                required: true,
                                onChange: (e) => handleInputChange('position', e.target.value)
                            })}
                        />

                        {errors.Position && (
                            <div className='errMsg text-danger'>Position is required</div>
                        )}

                    </Form.Group>


                    <Form.Group
                        className="mb-3"

                    >

                        <div className='row'>
                            <div className='col-12'>
                                <Form.Label>Image</Form.Label>
                                <Form.Control type="file"
                                    {...register("Image", {
                                        required: true,
                                        onChange: (e) => handleInputChange('image', e.target.files[0])
                                    })}
                                />

                                {errors.Image && (
                                    <div className='errMsg text-danger'>Please Select Image</div>
                                )}

                            </div>

                            <div className='col-12 text-center mb-3 mt-3'>
                                <Form.Label>Image Preview</Form.Label>
                                <div className='p-3'>

                                    {formData.image == "" ?

                                        <img src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTULSPiQKGEcCtCxrkr4t9Ub8U-Jwzv3kXu2RMOzQoihg&s`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                        :
                                        <img src={URL.createObjectURL(formData.image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                    }
                                </div>
                            </div>
                        </div>

                    </Form.Group>


                    <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label>Content</Form.Label>
                        <Form.Control as="textarea" rows={4} placeholder="Content" id="content" value={formData.content}
                            {...register("content", {
                                required: true,
                                onChange: (e) => handleInputChange('content', e.target.value)
                            })}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="stars">Rating</Form.Label>
                        <Form.Select
                            type="type"
                            id="rating"
                            placeholder="Rating"
                            value={formData.rating}
                            {...register("rating", {
                                required: true,
                                onChange: (e) => handleInputChange("rating", e.target.value),
                            })}
                        >
                            <option value="">Select Type</option>
                            <option value="1">1 start</option>
                            <option value="2">2 start</option>
                            <option value="3">3 start</option>
                            <option value="4">4 start</option>
                            <option value="5">5 start</option>
                        </Form.Select>
                        {errors.rating && (
                            <div className="errMsg text-danger">Please Select Rating</div>
                        )}
                    </Form.Group>


                    <Modal.Footer>
                        <Button type="submit" variant="contained" className='text-white' style={{ backgroundColor: '#0C0833' }}  >
                            Add
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


            {/* update */}
            <Modal show={showUpdate} onHide={handleCloseUpdate} className='' style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>

                    <Modal.Title>Update Testimonial</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit1(onUpdate)}>
                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Title" id="name" value={updateFormData.name}
                            {...register1("updateName", {
                                required: false,
                                onChange: (e) => handleInputChange1('name', e.target.value)
                            })}
                        />

                        {errors1.Name && (
                            <div className='errMsg text-danger'>Name is required</div>
                        )}

                    </Form.Group>

                    <Form.Group className="mb-3 col-12">
                        <Form.Label>Position</Form.Label>
                        <Form.Control type="text" placeholder="Position" value={updateFormData.position}
                            {...register1("updatePosition", {
                                required: false,
                                onChange: (e) => handleInputChange1('position', e.target.value)
                            })}
                        />

                        {errors1.Position && (
                            <div className='errMsg text-danger'>Position is required</div>
                        )}

                    </Form.Group>


                    <Form.Group
                        className="mb-3"

                    >

                        <div className='row'>
                            <div className='col-12'>
                                <Form.Label>Image</Form.Label>
                                <Form.Control type="file"
                                    {...register1("updateImage", {
                                        required: false,
                                        onChange: (e) => handleInputChange1('image', e.target.files[0])
                                    })}
                                />

                                {errors1.Image && (
                                    <div className='errMsg text-danger'>Please Select Image</div>
                                )}

                            </div>

                            <div className='col-12 text-center mb-3 mt-3'>
                                <Form.Label>Image Preview</Form.Label>
                                <div className='p-3'>

                                    {!updateFormData.image ?

                                        <img src={`${apiUrl}/static/assets/${image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                        :
                                        <img src={URL.createObjectURL(updateFormData.image)} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                    }
                                </div>
                            </div>
                        </div>

                    </Form.Group>


                    <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Form.Label>Content</Form.Label>
                        <Form.Control as="textarea" rows={4} placeholder="Content" id="content" value={updateFormData.content}
                            {...register1("updateContent", {
                                required: false,
                                onChange: (e) => handleInputChange1('content', e.target.value)
                            })}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="rating">Rating</Form.Label>
                        <Form.Select
                            type="type"
                            id="rating"
                            placeholder="Rating"
                            value={updateFormData.rating}
                            {...register1("rating", {
                                required: true,
                                onChange: (e) => handleInputChange1("rating", e.target.value),
                            })}
                        >
                            <option value="">Select Type</option>
                            <option value="1">1 start</option>
                            <option value="2">2 start</option>
                            <option value="3">3 start</option>
                            <option value="4">4 start</option>
                            <option value="5">5 start</option>
                        </Form.Select>
                        {errors1.rating && (
                            <div className="errMsg text-danger">Please Select Rating</div>
                        )}
                    </Form.Group>


                    {/* <div className='btn-d d-flex justify-content-center'>
                        <Button type='submit' className='text-white' variant='contained'>
                            Update Testimonials
                        </Button>
                    </div> */}
                    <Modal.Footer>
                        <Button type="submit" variant="contained" className='text-white' style={{ backgroundColor: '#0C0833' }}  >
                            Update
                        </Button>
                        <Button variant="secondary" onClick={handleCloseUpdate}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Form>

            </Modal>
        </>
    )
}

export default Testimonials